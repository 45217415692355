<template>
  <div>
    <div class="title-contidtion">
      {{ dateRange[0] ? `${dateRange[0]} ~ ${dateRange[1]}` : '本周' }}
      <!-- <svg v-show="dateRange[0]" class="icon" aria-hidden="true" @click="showCalendar=true">
        <use xlink:href="iconshaixuan-blue"></use>
      </svg> -->
      <svg class="icon" aria-hidden="true" @click="showCalendar=true">
        <use :xlink:href="`#iconshijianshaixuan${dateRange[0]?'-blue':''}`"></use>
      </svg>
    </div>
    <!-- <van-popup v-model="showCalendar" :style="{ width: '100%', height: '60%' }" position="bottom"> -->
    <!-- <div class="reset-btn" @click="resetDate">
        <div>
          <svg class="icon" aria-hidden="true" style="width:16px;height:16px;margin-right:3px;" @click="showCalendar=true">
            <use xlink:href="#iconzhongzhishaixuan">
            </use>
          </svg>重置筛选
        </div>
      </div> -->
    <van-calendar ref="datePicker" v-model="showCalendar" color="#3A74F2" class="van-calendar-range" :min-date="minDate" :max-date="maxDate" type="range" @confirm="confirmDate">
      <template #title>
        <div class="date-title">
          <div class="reset-btn" @click="resetDate">重置</div>
          <div class="date-title-desc">筛选</div>
          <div class="container-box"></div>
        </div>
      </template>
    </van-calendar>
    <!-- </van-popup> -->
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'filedtitle',
  data() {
    return {
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      showCalendar: false,
    };
  },
  props: {
    title: {
      type: String,
    },
  },
  computed: mapState('fieldManage', {
    dateRange: state => state.dateRange,
  }),
  methods: {
    ...mapMutations('fieldManage', ['setDateRange', 'setDateRange']),
    resetDate() {
      this.setDateRange([]);
      this.$refs.datePicker.reset();
      this.showCalendar = false;
      this.$emit('closeCondition');
    },
    confirmDate(val) {
      if (val.length > 1) {
        this.startDate = dayjs(val[0]).format('YYYY/MM/DD');
        this.endDate = dayjs(val[1]).format('YYYY/MM/DD');
        this.setDateRange([this.startDate, this.endDate]);
      }
      this.showCalendar = false;
      this.$emit('closeCondition');
    },
  },
};
</script>

<style lang="less" scoped>
.title-contidtion {
  padding: 0 16px;
  color: #909399;
  line-height: 40px;
  font-size: 12px;
  height: 44px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// .reset-btn {
//   position: fixed;
//   text-align: center;
//   line-height: 32px;
//   top: 22%;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 108px;
//   height: 32px;
//   background: #ffffff;
//   border-radius: 16px;
//   color: #3a74f2;
// }
.date-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  border-bottom: 1px solid #ebeef5;
  .container-box{
    width: 56px;
  }
}
.date-title-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.reset-btn {
  width: 56px;
  height: 28px;
  border-radius: 4px;
  font-size: 14px;
  color: #606266;
  line-height: 28px;
  text-align: center;
}
.date-title-desc {
  font-size: 15px;
  font-weight: bold;
  color: #303133;
}
</style>
