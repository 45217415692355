import request from '../../util/request';
import { CRM, CLUE } from '../apiConst';

export default {
  //获取客户趋势数据
  getTrendData (params) {
    return request.get({ url: `${CRM}/crm/custom/v1/trend/statistics`, params });
  },
  //获取客户趋势数据
  getTrendCard (data) {
    return request.post({
      url: `${CRM}/crm/custom/v1/follow/cards?estateId=${localStorage.getItem('platform_estateId')}`,
      data
    });
  },
  //获取潜客趋势数据
  getPotentTrendData (params) {
    return request.get({ url: `${CLUE}/clue/statistics/v1/trend`, params });
  },
  //获取潜客构成
  getPotentComposition (params) {
    return request.get({ url: `${CLUE}/clue/analysis/v1/overall/sale-clue-composition`, params });
  },
  //获取经理统计信息
  getPotentData (data) {
    return request.post({ url: `${CLUE}/sales-stat-info/v1/list`, data });
  },
  //获取跟进情况数据
  getfollowData (params) {
    return request.get({ url: `${CRM}/crm/custom/v1/follow-statistics-card`, params });
  },
  //获取逾期展示数据
  getOverdueData (params) {
    return request.get({ url: `${CRM}/crm/custom/v1/overdue-statistics-card`, params });
  },
  //获取成交展示数据
  getDealData (params) {
    return request.get({ url: `${CRM}/crm/custom/v1/deal-statistics-card`, params });
  },
  //获取成交列表
  getDealList (data) {
    return request.post({
      url: `${CRM}/crm/custom/v1/deal-statistics-page?estateId=${localStorage.getItem('platform_estateId')}`,
      data
    });
  },
  //获取跟进列表
  getFollowList (params) {
    return request.get({ url: `${CRM}/crm/custom/v1/follow-statistics-saleusers`, params });
  },
  //获取逾期列表
  getOverdueList (params) {
    return request.get({ url: `${CRM}/crm/custom/v1/overdue-statistics-saleusers`, params });
  },
  getFollowDetailList (data) {
    return request.post({
      url: `${CRM}/crm/custom/v1/saleusers-follow-page?estateId=${localStorage.getItem('platform_estateId')}`,
      data
    });
  },
  getOverdueDetailList (data) {
    return request.post({
      url: `${CRM}/crm/custom/v1/saleusers-overdue-page?estateId=${localStorage.getItem('platform_estateId')}`,
      data
    });
  }
};
