<!--潜客统计-->
<template>
  <div class="potential-static">
    <div class="bg">
      <div class="static-all">
        <div class="all-title">潜客数据总览</div>
        <div class="num-list">
          <div class="num-item" v-for="(item, index) of allData" :key="index">
            <div class="num">{{ item.num }}</div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="trend">
      <div class="trend-title">
        <van-popover
          get-container="potential-static"
          :offset="[12, 8]"
          class="popover"
          v-model="showPopover"
          :actions="trendActions"
          @select="trendSelect"
          trigger="click"
        >
          <template #reference>
            <div class="trend-select">
              <span class="title"> {{ trendTimeLint[trendConditionText] }}·潜客趋势 </span>
              <svg
                class="icon-arrow"
                aria-hidden="true"
                :class="{ 'icon-active': showPopover, 'icon-leave': !showPopover }"
              >
                <use xlink:href="#iconxialajiantou"></use>
              </svg>
            </div>
          </template>
        </van-popover>
      </div>
      <div class="trend-content">
        <div class="trend-val-group" v-for="(item, index) in trendCard" :key="index">
          <div class="trend-num num-font">{{ item.count }}</div>
          <div :class="`trend-val${index + 1}`">{{ trendLintMap[item.type].text }}</div>
        </div>
      </div>
      <wxb-chart-line
        :dataList="seriesArr"
        :legendArr="legendArr"
        :isInit="isInitChart"
        :xAxisArr="xAxisArr"
        :colorArr="colorArr"
      ></wxb-chart-line>
    </div>
    <div class="statistical">
      <div class="title">
        员工统计
      </div>
      <div class="sort">
        <van-popover
          get-container="potential-static"
          :offset="[16, 8]"
          class="popover"
          v-model="showPersonPopover"
          :actions="personActions"
          @select="personSelect"
          trigger="click"
        >
          <template #reference>
            <div class="trend-select">
              <span>{{ personConditionText }}</span>
              <svg
                class="icon-arrow"
                aria-hidden="true"
                :class="{ 'icon-active': showPersonPopover, 'icon-leave': !showPersonPopover }"
              >
                <use xlink:href="#iconxialajiantou"></use>
              </svg>
            </div>
          </template>
        </van-popover>

        <div class="time" @click="timeShow = true">
          {{ sortTime }}
          <svg class="icon-arrow" aria-hidden="true">
            <use xlink:href="#iconxialajiantou"></use>
          </svg>
        </div>
      </div>
      <van-list
        v-if="personList.length > 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="100"
      >
        <div class="person-item" v-for="(item, index) of personList" :key="index">
          <div class="person-heard">
            <img :src="item.avatar" alt="" :onerror="defaultImg" />
            <div class="van-ellipsis">{{ item.saleName }}</div>
          </div>
          <div class="person-count1">
            <div class="group">
              <div class="count">{{ item.clueNumber }}</div>
              <div class="count-name">新增潜客</div>
            </div>
            <div class="group">
              <div class="count">{{ item.talkNumber }}</div>
              <div class="count-name">聊天数</div>
            </div>
          </div>
          <div class="person-count2">
            <div class="group">
              <div class="count">{{ item.converseNumber }}</div>
              <div class="count-name">转化潜客</div>
            </div>
            <div class="group">
             <div class="count">{{ item.messageNumber }}</div>
              <div class="count-name">发送消息</div>
            </div>
          </div>
          <div class="person-count3">
            <div class="group">
              <div class="count">{{ item.wechatNumber }}</div>
              <div class="count-name">新增好友</div>
            </div>
            <div class="group">
              <div class="count">{{ item.turnoverNumber }}</div>
              <div class="count-name">客户流失</div>
            </div>
          </div>
           <div class="person-count3">
            <div class="group">
              <div class="count">{{ item.delfriendNumber }}</div>
              <div class="count-name">删除好友</div>
            </div>
            <div class="group">
            </div>
          </div>
        </div>
      </van-list>
      <wxb-no-data v-else />
    </div>

    <!--时间选择-->
    <van-popup v-model="timeShow" round position="bottom" :close-on-click-overlay="false">
      <wxb-change-week v-model="currentDate" v-show="checkType === 'week'" ref="weeks">
        <div class="time-heard">
          <div @click="resetTime">重置</div>
          <div class="time-type">
            <div :class="['type', checkType === 'day' ? 'check-type' : '']" @click="checkType = 'day'">按日</div>
            <div :class="['type', checkType === 'week' ? 'check-type' : '']" @click="checkType = 'week'">按周</div>
            <div :class="['type', checkType === 'month' ? 'check-type' : '']" @click="checkType = 'month'">按月</div>
          </div>
          <van-button @click="timeOk" size="small" class="btn">确定</van-button>
        </div>
      </wxb-change-week>
      <van-datetime-picker
        v-show="checkType != 'week'"
        v-model="currentDate"
        :swipe-duration="280"
        :type="timeType"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
      >
        <div class="time-heard">
          <div @click="resetTime">重置</div>
          <div class="time-type">
            <div :class="['type', checkType === 'day' ? 'check-type' : '']" @click="checkType = 'day'">按日</div>
            <div :class="['type', checkType === 'week' ? 'check-type' : '']" @click="checkType = 'week'">按周</div>
            <div :class="['type', checkType === 'month' ? 'check-type' : '']" @click="checkType = 'month'">按月</div>
          </div>
          <van-button @click="timeOk" size="small" class="btn">确定</van-button>
        </div>
      </van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
import field from '@/api/fieldManage';
import dayjs from 'dayjs';

const trendTimeLint = {
  按日统计: '今日',
  按周统计: '本周',
  按月统计: '本月',
};
const trendLintMap = {
  1: { text: '新增', color: '#6AD038' },
  2: { text: '留电', color: '#FFA827' },
  3: { text: '加企微', color: '#18D0CC' },
  4: { text: '转为客户', color: '#3A74F2' },
};
const trendActionsLint = {
  按日统计: 0,
  按周统计: 1,
  按月统计: 2,
};
const personActionsLint = {
  按新增好友排序: 1,
  按聊天数排序: 3,
  按发送消息排序: 4,
  按转化潜客排序: 5,
};
export default {
  name: 'potentialStatic',
  computed: {
    defaultImg() {
      return 'this.src="' + require('@/assets/images/default_head_icon.png') + '"';
    },
  },
  data() {
    return {
      allData: [
        { name: '未转化', num: 0 },
        { name: '已转化', num: 0 },
        { name: '留电', num: 0 },
        { name: '好友', num: 0 },
        { name: '无效', num: 0 },
      ],
      //潜客趋势图表
      trendTimeLint,
      trendLintMap,
      trendActionsLint,
      trendCard: [],
      seriesArr: [], //图表相关参数
      xAxisArr: [], //图表相关参数
      colorArr: [], //图表相关参数
      legendArr: [],
      isInitChart: false, //图表相关参数,
      showPopover: false,
      trendConditionText: '按日统计',
      trendActions: [{ text: '按日统计' }, { text: '按周统计' }, { text: '按月统计' }],
      //员工统计
      checkType: 'day',
      timeShow: false,
      timeType: 'month-day',
      minDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      sortTime: dayjs().format('MM月-DD日'),
      time: dayjs().format('YYYY-MM-DD 00:00:00'),
      showPersonPopover: false,
      personConditionText: '按新增好友排序',
      personActionsLint,
      personActions: [
        { text: '按新增好友排序' },
        { text: '按聊天数排序' },
        { text: '按发送消息排序' },
        { text: '按转化潜客排序' },
      ],
      pageNation: {
        pageNo: 0,
        pageSize: 20,
        totalRecords: 0,
      },
      loading: false,
      finished: false,
      personList: [],
    };
  },
  watch: {
    checkType(val) {
      switch (val) {
        case 'day':
          this.currentDate = new Date();
          this.timeType = 'month-day';
          break;
        case 'week':
          this.currentDate = this.$refs.weeks.columns[1].values[this.$refs.weeks.columns[1].defaultIndex];
          break;
        case 'month':
          this.currentDate = new Date();
          this.timeType = 'year-month';
          break;
        default:
          break;
      }
    },
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day' && this.checkType !== 'week') {
        return val + '日';
      }
      return val;
    },
    trendSelect({ text }) {
      this.trendConditionText = text;
      // this.getTrendCard();
      this.getPotentTrendData();
      setTimeout(() => {
        this.showPopover = false;
      });
    },
    //获取潜客趋势
    async getPotentTrendData() {
      this.isInitChart = false;
      let { code, data } = await field.getPotentTrendData({
        opId: localStorage.getItem('platform_opId'),
        estateId: localStorage.getItem('platform_estateId'),
        timePhase: this.trendActionsLint[this.trendConditionText],
        timeInterval: 7,
      });
      if (code === '0') {
        this.trendCard = [];
        data.map((item) => {
          let addCount = 0;
          let phoneCount = 0;
          let wxCount = 0;
          let customerCount = 0;
          switch (item.statisticalType) {
            case 1:
              addCount = item.clueTimeTrendVoList[item.clueTimeTrendVoList.length - 1].count;
              this.trendCard.push({ type: 1, count: addCount });
              break;
            case 2:
              phoneCount = item.clueTimeTrendVoList[item.clueTimeTrendVoList.length - 1].count;
              this.trendCard.push({ type: 2, count: phoneCount });
              break;
            case 3:
              wxCount = item.clueTimeTrendVoList[item.clueTimeTrendVoList.length - 1].count;
              this.trendCard.push({ type: 3, count: wxCount });
              break;
            case 4:
              customerCount = item.clueTimeTrendVoList[item.clueTimeTrendVoList.length - 1].count;
              this.trendCard.push({ type: 4, count: customerCount });
              break;
            default:
              break;
          }
        });
        this.dataSet(data);
      }
    },
    dataSet(dataList) {
      let xAxisArr = []; //x轴日期时间
      let seriesArr = []; //折线数据 数组
      let colorArr = [];
      let legendArr = []; //折线数据 每组title
      for (let i = 0; i < dataList.length; i++) {
        const element = dataList[i];
        let cusTimeTrendList = element.clueTimeTrendVoList;
        let seriesName = this.trendLintMap[element.statisticalType].text;
        colorArr.push(this.trendLintMap[element.statisticalType].color);
        legendArr.push(seriesName);
        let seriesItem = {
          //y轴数据
          name: seriesName,
          type: 'line',
          smooth: true,
          data: [],
          itemStyle: {
            normal: {
              lineStyle: {
                width: 1.2, //设置线条粗细
              },
            },
          },
        };
        cusTimeTrendList.forEach((item) => {
          seriesItem.data.push(item.count);
          if (i == 0) {
            xAxisArr.push(item.timeNode);
          }
        });
        seriesArr.push(seriesItem);
      }
      this.seriesArr = seriesArr; //[{ 'name': '认购', 'type': 'line', 'data': [100, 10, 200, 300, 220, 120, 120] }, { 'name': '认筹', 'type': 'line', 'data': [10, 220, 220, 330, 440, 120, 110] }, { 'name': '签约', 'type': 'line', 'data': [110, 130, 150, 120, 110, 100, 10] }, { 'name': '来访', 'type': 'line', 'data': [10, 20, 30, 40, 70, 80, 100] }]; //seriesArr;
      this.xAxisArr = xAxisArr;
      this.colorArr = colorArr;
      this.legendArr = legendArr;
      this.isInitChart = true;
    },
    //获取潜客数据总览
    async getPotentComposition() {
      let params = {
        opId: localStorage.getItem('platform_opId'),
        estateId: localStorage.getItem('platform_estateId'),
      };
      let { code, data } = await field.getPotentComposition(params);
      if (code === '0') {
        for (let item of data) {
          switch (item.clueType) {
            case 0:
              this.allData[0].num = item.clueCount;
              break;
            case 1:
              this.allData[1].num = item.clueCount;
              break;
            case 2:
              this.allData[2].num = item.clueCount;
              break;
            case 3:
              this.allData[3].num = item.clueCount;
              break;
            case 4:
              this.allData[4].num = item.clueCount;
              break;
            default:
              break;
          }
        }
      }
    },

    //员工统计
    resetTime() {
      this.currentDate = new Date();
      this.checkType = 'day';
      this.timeShow = false;
      this.timeOk();
    },
    personSelect({ text }) {
      this.personConditionText = text;
      this.finished = false;
      this.pageNation.pageNo = 1;
      this.personList = [];
      this.getPersonList();
      setTimeout(() => {
        this.showPersonPopover = false;
      });
    },
    timeOk() {
      switch (this.checkType) {
        case 'day':
          this.sortTime = dayjs(this.currentDate).format('MM月-DD日');
          this.time = dayjs(this.currentDate).format('YYYY-MM-DD 00:00:00');
          break;
        case 'week':
          this.sortTime = dayjs(this.currentDate.value).format('YYYY年') + this.currentDate.text;
          this.time = dayjs(this.currentDate.value).format('YYYY-MM-DD HH:mm:ss');
          break;
        case 'month':
          this.sortTime = dayjs(this.currentDate).format('YYYY年-MM月');
          this.time = dayjs(this.currentDate).format('YYYY-MM-01 00:00:00');
          break;
      }
      console.log( this.sortTime, '333');
      this.timeShow = false;
      this.finished = false;
      this.pageNation.pageNo = 1;
      this.personList = [];
      this.getPersonList();
    },
    onLoad() {
      this.pageNation.pageNo += 1;
      this.getPersonList();
    },
    async getPersonList() {
      let params = {
        opId: localStorage.getItem('platform_opId'),
        estateId: localStorage.getItem('platform_estateId'),
        periodType: this.checkType === 'day' ? 0 : this.checkType === 'week' ? 1 : 2,
        periodStartTime: this.time,
        sorted: 2,
        sortedType: this.personActionsLint[this.personConditionText],
        pageNo: this.pageNation.pageNo,
        pageSize: this.pageNation.pageSize,
      };
      let { code, data } = await field.getPotentData(params);
      this.loading = false;
      if (code === '0') {
        this.personList = this.personList.concat(data.data);
        this.pageNation.totalRecords = data.totalRecords;
        if (this.personList.length >= this.pageNation.totalRecords) {
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
    },
  },
  mounted() {
    this.getPotentTrendData();
    this.getPotentComposition();
    this.onLoad();
  },
};
</script>

<style lang="less" scoped>
.abnormal {
  padding: 40px 64px;
  height: 100vh;
  background-color: @gary-white;

  img {
    width: 240px;
    height: 148px;
  }

  .describe {
    line-height: 24px;
    margin-top: 8px;
    color: #303133;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
  }
}

.potential-static {
  padding-bottom: calc(69px + env(safe-area-inset-bottom));

  .bg {
    height: 88px;
    background: linear-gradient(
      360deg,
      rgba(57, 116, 198, 0) 0%,
      rgba(57, 116, 198, 0.02) 9%,
      rgba(57, 116, 198, 0.05) 17%,
      rgba(57, 116, 198, 0.12) 24%,
      rgba(57, 116, 198, 0.2) 31%,
      rgba(57, 116, 198, 0.29) 37%,
      rgba(57, 116, 198, 0.39) 44%,
      rgba(57, 116, 198, 0.5) 50%,
      rgba(57, 116, 198, 0.61) 56%,
      rgba(57, 116, 198, 0.71) 63%,
      rgba(57, 116, 198, 0.8) 69%,
      rgba(57, 116, 198, 0.88) 76%,
      rgba(57, 116, 198, 0.95) 83%,
      rgba(57, 116, 198, 0.98) 91%,
      #3974c6 100%
    );
    position: relative;

    .static-all {
      position: absolute;
      top: 12px;
      left: 12px;
      background: #ffffff;
      border-radius: 4px;
      padding-top: 17px;
      padding-bottom: 24px;
      width: 351px;

      .all-title {
        margin: 0px 0px 31px 12px;
        font-size: 17px;
        font-weight: bold;
        color: @gary-wxb-black-1;
        line-height: 24px;
      }

      .num-list {
        display: flex;
        align-items: center;

        .num-item {
          width: 70px;
          text-align: center;

          .num {
            font-size: 24px;
            font-family: "SSI_Black";
            color: #3a74f2;
            line-height: 26px;
          }

          .name {
            margin-top: 4px;
            margin-bottom: 2px;
            font-size: 12px;
            color: @gary-wxb-black-1;
            line-height: 17px;
          }
        }
      }
    }
  }

  .num-font {
    font-family: "SSI_Black";
  }

  @colorArr: linear-gradient(180deg, #6ad038 0%, rgba(163, 234, 105, 0) 100%),
    linear-gradient(180deg, #ffa827 0%, rgba(255, 212, 79, 0) 100%),
    linear-gradient(180deg, #18d0cc 0%, rgba(24, 208, 204, 0) 100%),
    linear-gradient(180deg, #3a74f2 0%, rgba(108, 172, 250, 0) 100%);

  @len: length(@colorArr);

  .Loop(@index) when (@index<@len+1) {
    .trend-val@{index} {
      // margin-top: 4px;
      font-size: 12px;
      color: #303133;
      line-height: 17px;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 12px;
        margin-right: 4px;
        border-radius: 2px;
        background: extract(@colorArr, @index);
      }
    }

    .Loop(@index+1);
  }
  .Loop(1);

  .icon-arrow {
    width: 6px;
    height: 6px;
    margin-left: 4px;
  }

  .trend {
    margin: 79px 12px 12px;
    background: #ffffff;
    border-radius: 4px;

    &-title {
      padding: 16px;
      display: flex;
      align-items: center;
    }

    &-select {
      color: #303133;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      .title {
        color: #303133;
        font-size: 17px;
        font-weight: bold;
      }
    }

    &-icon {
      margin-left: 3px;
    }

    &-content {
      padding-top: 18px;
      display: flex;
      flex-wrap: wrap;
    }

    &-val-group {
      flex: 0 0 25%;
      text-align: center;
    }

    &-num {
      color: #303133;
      font-size: 20px;
      text-align: center;
    }

    .color1 {
      background: linear-gradient(180deg, #6ad038 0%, rgba(163, 234, 105, 0) 100%);
    }

    .color2 {
      background: linear-gradient(180deg, #ffa827 0%, rgba(255, 212, 79, 0) 100%);
    }

    .color3 {
      background: linear-gradient(180deg, #18d0cc 0%, rgba(24, 208, 204, 0) 100%);
    }

    .color4 {
      background: linear-gradient(180deg, #3a74f2 0%, rgba(108, 172, 250, 0) 100%);
    }
  }

  .statistical {
    border-radius: 4px;
    background-color: #ffffff;
    margin: 0px 12px;

    .title {
      padding: 16px 12px;
      font-size: 17px;
      font-weight: bold;
      color: @gary-wxb-black-1;
      line-height: 24px;
    }

    .sort {
      padding: 6px 12px;
      display: flex;
      align-items: center;

      .time {
        font-size: 14px;
        color: @gary-wxb-black-1;
        line-height: 20px;
        margin-left: 24px;
        display: flex;
        align-items: center;
      }
    }

    .person-item {
      padding: 0 16px;
      border-bottom: 1px solid #f6f6f6;
      display: flex;
      align-items: center;

      .person-heard {
        height: 80px;
        width: 48px;
        padding: 16px 0px;

        img {
          width: 48px;
          height: 48px;
          border-radius: 8px;
          border: 1px solid #ebeef5;
        }

        div {
          width: 48px;
          margin-top: 8px;
          font-size: 15px;
          font-weight: bold;
          color: @gary-wxb-black-1;
          line-height: 21px;
        }
      }

      .person-count1 {
        margin-left: 24px;
        width: 88px;
        height: 80px;
        padding: 16px 0px;

        .group {
          .count {
            font-size: 12px;
            font-weight: bold;
            color: @gary-wxb-black-1;
            line-height: 17px;
          }

          .count-name {
            font-size: 12px;
            color: @gary-wxb-gray-3;
            line-height: 17px;
          }

          &:last-child {
            margin-top: 14px;
          }
        }
      }

      .person-count2 {
        width: 88px;
        height: 80px;
        padding: 16px 0px;

        .group {
          .count {
            font-size: 12px;
            font-weight: bold;
            color: @gary-wxb-black-1;
            line-height: 17px;
          }

          .count-name {
            font-size: 12px;
            color: @gary-wxb-gray-3;
            line-height: 17px;
          }

          &:last-child {
            margin-top: 14px;
          }
        }
      }

      .person-count3 {
        width: 88px;
        height: 80px;
        padding: 16px 0px;

        .other {
          height: 34px;
        }

        .group {
          .count {
            font-size: 12px;
            font-weight: bold;
            color: @gary-wxb-black-1;
            line-height: 17px;
          }

          .count-name {
            font-size: 12px;
            color: @gary-wxb-gray-3;
            line-height: 17px;
          }

          &:last-child {
            margin-top: 14px;
          }
        }
      }
    }
  }

  .time-heard {
    width: 100%;
    padding: 3px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebeef5;

    .time-type {
      width: 138px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .type {
        font-size: 15px;
        color: @gary-wxb-gray-2;
        line-height: 21px;
      }

      .check-type {
        color: @blue;
        position: relative;

        &:after {
          left: 7px;
          bottom: -7px;
          position: absolute;
          content: "";
          width: 16px;
          height: 2px;
          border-radius: 1px;
          background-color: @blue;
        }
      }
    }

    .btn {
      padding: 4px 14px;
      background: @blue;
      border-radius: 4px;
      font-size: 12px !important;
      line-height: 17px;
      color: #ffffff;
    }
  }
}
</style>
<style lang="less">
.potential-static {
  .van-popover-zoom-enter-active {
    transform-origin: 0 0 0 !important;
  }

  .van-popover-zoom-leave-active {
    transform-origin: 0 0 0 !important;
  }
}
</style>
