<template>
  <div class="fieldList">
    <div class="title">{{ type === 'follow' ? '跟进情况' : '当前逾期情况' }}</div>
    <div class="content">
      <div v-for="(item, index) in contentArr" :key="index">
        <div @click="toDetail(item)">
          <!-- {{item}} -->
          <div class="num">{{ item.num }}</div>
          <div class="val">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    type: {
      type: String,
    },
    contentArr: {
      type: Array,
    },
  },
  methods: {
    ...mapMutations('fieldManage', ['setFollowOverdueType']),
    toDetail({ val }) {
      this.setFollowOverdueType(this.type);
      sessionStorage.setItem('crmFollowOrOverdue', this.type);
      window.location.href = window.location.origin + '/crm/#/' + `fieldManage/followOverdueList?type=${this.type}&tab=${val}`;
    },
  },
};
</script>

<style lang="less" scoped>
.fieldList {
  background: #fff;
  padding: 16px 16px 24px;
}
.title {
  color: #303133;
  font-size: 17px;
  font-weight: bold;
}
.content {
  margin: 28px 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.num {
  text-align: center;
  color: #3a74f2;
  font-size: 24px;
  font-family: 'SSI_Black';
}
.val {
  margin-top: 2px;
  text-align: center;
  font-size: 12px;
  color: #303133;
}
</style>
