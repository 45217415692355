<template>
  <div class="client-list">
    <van-tabs v-if="showCrm && showPotent" swipeable v-model="active" class="client-tab">
      <van-tab title="客户统计" :name="0">
        <customerStatic v-if="active === 0"></customerStatic>
      </van-tab>
      <van-tab title="潜客统计" :name="1">
        <potentialStatic v-if="active === 1" />
      </van-tab>
    </van-tabs>
    <customerStatic v-else-if="showCrm"></customerStatic>
    <potentialStatic v-else-if="showPotent" />
    <div class="abnormal" v-else>
      <img src="@/assets/images/noAuth.png" alt="" />
      <div class="describe">
        暂无权限
      </div>
    </div>
    <h5-navigater />
  </div>
</template>

<script>
import customerStatic from './components/customerStatic';
import potentialStatic from './components/potentialStatic';

export default {
  components: {
    customerStatic,
    potentialStatic,
  },
  data() {
    return {
      showPotent: false,
      showCrm: false,
      active: 0,
    };
  },
  mounted() {
    let appPermits = JSON.parse(localStorage.getItem('platform_appPermit'));
    this.showPotent = appPermits.some((item) => item === 'potential');
    this.showCrm = appPermits.some((item) => item === 'scrm');
  },
};
</script>

<style lang="less">
.abnormal {
  padding: 40px 64px;
  height: 100vh;
  background-color: @gary-white;

  img {
    width: 240px;
    height: 148px;
  }

  .describe {
    line-height: 24px;
    margin-top: 8px;
    color: #303133;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
  }
}
.client-list {
  .client-tab {
    .van-tabs__wrap {
      background: @top-blue;
      box-sizing: border-box;
      border: none;
      padding: 6px 16px;
      position: relative;
      font-size: 14px;
    }

    .van-tab {
      color: @gary-wxb-gray-3;
    }

    .van-tabs__nav {
      background: #f5f6f7;
      border-radius: 4px;
      color: @gary-wxb-gray-3;
      display: flex;
      align-items: center;
      height: 32px;
      line-height: 32px;
      padding: 0 2px;
    }

    .van-tab--active {
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      color: @gary-wxb-black-1;
      height: 28px;
      line-height: 28px;
      font-weight: 400;
    }

    .van-tabs__nav--line {
      padding-bottom: 0;
    }

    .van-tabs__line {
      display: none;
    }
  }
}
</style>
