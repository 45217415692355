<!--客户统计-->
<template>
  <div class="filedManage">
    <filed-title @closeCondition="dealConditionSelect" />
    <div class="trend" v-if="!isConditionSelect">
      <div class="trend-title">
        <van-popover
          get-container="filedManage"
          :offset="[12, 8]"
          class="popover"
          v-model="showPopover"
          :actions="trendActions"
          @select="trendSelect"
          trigger="click"
        >
          <template #reference>
            <div class="trend-select">
              <span class="title">{{ trendTimeLint[trendConditionText] }}·客户趋势</span>
              <svg
                class="icon-arrow"
                aria-hidden="true"
                :class="{ 'icon-active': showPopover, 'icon-leave': !showPopover }"
              >
                <use xlink:href="#iconxialajiantou"></use>
              </svg>
            </div>
          </template>
        </van-popover>
      </div>
      <div class="trend-content">
        <div class="trend-val-group" v-for="(item, index) in trendCard" :key="index">
          <div class="trend-num num-font">{{ item.count }}</div>
          <div :class="`trend-val${index + 1}`">{{ trendLintMap[item.type].text }}</div>
        </div>
      </div>
      <wxb-chart-line
        :dataList="seriesArr"
        :legendArr="legendArr"
        :isInit="isInitChart"
        :xAxisArr="xAxisArr"
        :colorArr="colorArr"
      ></wxb-chart-line>
    </div>
    <filed-list :contentArr="followData" type="follow" />
    <filed-list v-if="!isConditionSelect" style="margin-top: 12px;" :contentArr="overdueData" type="overdue" />
    <div class="deal">
      <div class="deal-title">认筹&成单</div>
      <div class="deal-field" @click="toDealDetail(item)" v-for="(item, index) in dealData" :key="index">
        <div class="d-fields">
          <div :class="`d-fields-title filed-color${item.type}`">{{ dealLintMap[item.type] }}</div>
          <div class="d-normal-font d-normal-color">
            <!-- <span v-if="typeof item.count !== 'undefined'"> -->
            <span class="d-bold-color"> {{ item.count }} </span>
            <span>笔</span>
            <!-- </span> -->
            <!-- <span v-if="typeof item.amount !== 'undefined'"> -->
            <span> ;</span>
            <span class="d-bold-color"> {{ item.amount }} </span>
            <span>万元</span>
            <!-- </span> -->
          </div>
        </div>
        <div class="line"></div>
        <div class="d-fields-2">
          <div class="d-big-font d-normal-color">
            <span>新增 </span>
            <span class="d-bold-color">{{ item.newCount }}</span>
            <span> 笔</span>
            <span> ；关闭 </span>
            <span class="d-bold-color">{{ item.closeCount }}</span>
            <span> 笔</span>
          </div>
          <van-icon name="arrow" color="#DCDFE6" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FiledTitle from './filedTitle';
import FiledList from './filedList';
import field from '@/api/fieldManage';
import { mapState } from 'vuex';
// import login from '../../api/login';
const followLintMap = {
  21: '来电',
  22: '去电',
  23: '微信',
  25: '其他',
  30: '来访',
};

const trendLintMap = {
  30: { text: '来访', color: '#6AD038' },
  40: { text: '认筹', color: '#FFA827' },
  50: { text: '认购', color: '#18D0CC' },
  60: { text: '签约', color: '#3A74F2' },
};

const overdueLintMap = {
  0: '未跟进',
  1: '未来访',
  2: '未认筹',
  3: '未认购',
};

const dealLintMap = {
  0: '认筹',
  1: '认购',
  2: '签约',
};

const trendActionsLint = {
  按日统计: 0,
  按周统计: 1,
  按月统计: 2,
};

const trendTimeLint = {
  按日统计: '今日',
  按周统计: '本周',
  按月统计: '本月',
};

export default {
  name: 'customerStatic',
  components: {
    FiledList,
    FiledTitle,
  },
  data() {
    return {
      isConditionSelect: false,
      seriesArr: [], //图表相关参数
      xAxisArr: [], //图表相关参数
      colorArr: [], //图表相关参数
      legendArr: [],
      isInitChart: false, //图表相关参数
      trendLintMap, //字典
      followLintMap, //字典
      overdueLintMap, //字典
      trendActionsLint,
      trendTimeLint,
      dealLintMap,
      trendCard: [
        // { type: 30, count: 10 }, { type: 40, count: 20 }, { type: 50, count: 30 }, { type: 60, count: 1 }
      ],
      dealData: [],
      followData: [],
      overdueData: [],
      chartSettings: {
        // yAxisName: ['kWh']
      },
      showCalendar: false,
      startDate: '',
      endDate: '',
      trendConditionText: '按日统计',
      showPopover: false,
      trendActions: [{ text: '按日统计' }, { text: '按周统计' }, { text: '按月统计' }],
    };
  },
  computed: {
    ...mapState('fieldManage', { dateRange: (state) => state.dateRange }),
  },
  mounted() {
    // login.setToken({
    //   tenantId: '1006701752890572861',
    //   appId: '1382277405342830511',
    //   userId: null,
    //   saleUserId: '1007568065410252862',
    //   userName: '张倩',
    // });
    this.getDataList();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == '/') {
      //必要的
      this.$wx.closeWindow();
    } else {
      next();
    }
  },
  methods: {
    dealConditionSelect() {
      if (this.dateRange.length > 0) {
        Promise.all([this.getfollowData(), this.getDealData()])
          .then()
          .catch((e) => console.log(e));
        this.isConditionSelect = true;
      } else {
        this.getDataList();
        this.isConditionSelect = false;
      }
    },
    getDataList() {
      Promise.all([
        this.getTrendData(),
        this.getfollowData(),
        this.getOverdueData(),
        this.getDealData(),
        this.getTrendCard(),
      ])
        .then()
        .catch((e) => console.log(e));
    },
    toDealDetail(item) {
      window.location.href =
        window.location.origin +
        '/crm/#/' +
        `fieldManage/dealList?title=${dealLintMap[item.type]}统计&type=${item.type}`;
    },
    async getTrendCard() {
      this.isInitChart = false;
      let { data } = await field.getTrendCard({
        estateId: localStorage.getItem('platform_estateId'),
        timePhase: this.trendActionsLint[this.trendConditionText],
      });
      this.trendCard = data;
      console.log('trendCard', data);
    },
    async getDealData() {
      let params = { estateId: localStorage.getItem('platform_estateId') };
      if (this.dateRange.length > 0) {
        params['startDate'] = this.dateRange[0];
        params['endDate'] = this.dateRange[1];
      }
      let { data } = await field.getDealData(params);
      this.dealData = data;
      console.log('data', data);
    },
    async getOverdueData() {
      let { data } = await field.getOverdueData({ estateId: localStorage.getItem('platform_estateId') });
      this.overdueData = data.map((item) => {
        let param = {
          text: this.overdueLintMap[item.type],
          val: item.type,
          num: item.count,
        };
        return param;
      });
      sessionStorage.setItem('crmOverdueData', JSON.stringify(this.overdueData));
      console.log('getOverdueData', this.overdueData);
    },
    async getfollowData() {
      let params = { estateId: localStorage.getItem('platform_estateId') };
      if (this.dateRange.length > 0) {
        params['startDate'] = this.dateRange[0];
        params['endDate'] = this.dateRange[1];
      }
      let { data } = await field.getfollowData(params);
      this.followData = data.map((item) => {
        let param = {
          text: this.followLintMap[item.type],
          num: item.count,
          val: item.type,
        };
        return param;
      });
      sessionStorage.setItem('crmFollowData', JSON.stringify(this.followData));

      console.log('followData', this.followData);
    },
    async getTrendData() {
      let { data } = await field.getTrendData({
        estateId: localStorage.getItem('platform_estateId'),
        timePhase: this.trendActionsLint[this.trendConditionText],
        timeInterval: 7,
      });
      this.dataSet(data);
    },
    trendSelect({ text }) {
      this.trendConditionText = text;
      setTimeout(() => {
        this.showPopover = false;
      });
      this.getTrendCard();
      this.getTrendData();
    },
    dataSet(dataList) {
      console.log('dataSet', dataList);
      let xAxisArr = []; //x轴日期时间
      let seriesArr = []; //折线数据 数组
      let colorArr = [];
      let legendArr = []; //折线数据 每组title
      for (let i = 0; i < dataList.length; i++) {
        const element = dataList[i];
        let cusTimeTrendList = element.cusTimeTrendList;
        let seriesName = this.trendLintMap[element.followType].text;
        colorArr.push(this.trendLintMap[element.followType].color);
        legendArr.push(seriesName);
        let seriesItem = {
          //y轴数据
          name: seriesName,
          type: 'line',
          smooth: true,
          data: [],
          itemStyle: {
            normal: {
              lineStyle: {
                width: 1.2, //设置线条粗细
              },
            },
          },
        };
        cusTimeTrendList.forEach((item) => {
          seriesItem.data.push(item.count);
          if (i == 0) {
            xAxisArr.push(item.timeNode);
          }
        });
        seriesArr.push(seriesItem);
      }
      // console.log('colorArr', colorArr);
      // console.log('xAxisArr', xAxisArr);
      // console.log('seriesArr', JSON.stringify(seriesArr));
      this.seriesArr = seriesArr; //[{ 'name': '认购', 'type': 'line', 'data': [100, 10, 200, 300, 220, 120, 120] }, { 'name': '认筹', 'type': 'line', 'data': [10, 220, 220, 330, 440, 120, 110] }, { 'name': '签约', 'type': 'line', 'data': [110, 130, 150, 120, 110, 100, 10] }, { 'name': '来访', 'type': 'line', 'data': [10, 20, 30, 40, 70, 80, 100] }]; //seriesArr;
      this.xAxisArr = xAxisArr;
      this.colorArr = colorArr;
      this.legendArr = legendArr;
      this.isInitChart = true;
      // option = {
      //   tooltip: {
      //     trigger: 'axis'
      //   },
      //   legend: {
      //     data: legendArr
      //   },
      //   grid: {
      //     left: '3%',
      //     right: '4%',
      //     bottom: '3%',
      //     containLabel: true
      //   },
      //   toolbox: {
      //     feature: {
      //       saveAsImage: {}
      //     }
      //   },
      //   xAxis: {
      //     type: 'category',
      //     boundaryGap: false,
      //     data: xAxisArr,
      //     axisLabel: {
      //       interval: 0,
      //       rotate: 0,
      //       fontSize: 12,
      //       padding: [0, 0, 0, 30],
      //       formatter: function (value) {
      //         return value.replace(/-/g, '.');
      //       }
      //     }
      //   },
      //   yAxis: {
      //     type: 'value',
      //     minInterval: 1 //显示间隔的规律
      //   },
      //   series: seriesArr
      // };
    },
  },
};
</script>
<style lang="less" scoped>
.abnormal {
  padding: 40px 64px;
  height: 100vh;
  background-color: @gary-white;

  img {
    width: 240px;
    height: 148px;
  }

  .describe {
    line-height: 24px;
    margin-top: 8px;
    color: #303133;
    font-weight: bold;
    font-size: 17px;
    text-align: center;
  }
}

.icon-arrow {
  width: 6px;
  height: 6px;
  margin-left: 4px;
}

.num-font {
  font-family: "SSI_Black";
}

.filedManage {
  padding-bottom: 50px;
}

@colorArr: linear-gradient(180deg, #6ad038 0%, rgba(163, 234, 105, 0) 100%),
  linear-gradient(180deg, #ffa827 0%, rgba(255, 212, 79, 0) 100%),
  linear-gradient(180deg, #18d0cc 0%, rgba(24, 208, 204, 0) 100%),
  linear-gradient(180deg, #3a74f2 0%, rgba(108, 172, 250, 0) 100%);

@len: length(@colorArr);

.Loop(@index) when (@index<@len+1

) {
  .trend-val@{index} {
    // margin-top: 4px;
    font-size: 12px;
    color: #303133;
    line-height: 17px;
    &::before {
      content: "";
      display: inline-block;
      width: 4px;
      height: 12px;
      margin-right: 4px;
      border-radius: 2px;
      background: extract(@colorArr, @index);
    }
  }

  .Loop(@index+1);
}

.Loop(1);

.trend {
  background: #fff;
  // padding: 16px;
  margin-bottom: 12px;

  &-title {
    padding: 16px;
    display: flex;
    align-items: center;
  }

  &-select {
    color: #303133;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    .title {
      color: #303133;
      font-size: 17px;
      font-weight: bold;
    }
  }

  &-icon {
    margin-left: 3px;
  }

  &-content {
    padding-top: 18px;
    display: flex;
    flex-wrap: wrap;
  }

  &-val-group {
    flex: 0 0 25%;
    text-align: center;
  }

  &-num {
    color: #303133;
    font-size: 20px;
    text-align: center;
  }

  .color1 {
    background: linear-gradient(180deg, #6ad038 0%, rgba(163, 234, 105, 0) 100%);
  }

  .color2 {
    background: linear-gradient(180deg, #ffa827 0%, rgba(255, 212, 79, 0) 100%);
  }

  .color3 {
    background: linear-gradient(180deg, #18d0cc 0%, rgba(24, 208, 204, 0) 100%);
  }

  .color4 {
    background: linear-gradient(180deg, #3a74f2 0%, rgba(108, 172, 250, 0) 100%);
  }
}

.deal {
  margin: 12px 0;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  padding: 16px 16px 40px;

  &-title {
    color: #303133;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  &-field {
    margin-top: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(48, 49, 51, 0.02);
    border-radius: 4px;
    border: 1px solid rgba(235, 238, 245, 0.4);
  }

  .d-fields {
    padding: 14px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .d-fields-2 {
    padding: 18px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .d-fields-title {
    width: 32px;
    height: 19px;
    line-height: 19px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }

  .filed-color0 {
    color: #ffa827;
    background: rgba(255, 168, 39, 0.1);
  }

  .filed-color1 {
    color: #18d0cc;
    background: rgba(24, 208, 204, 0.1);
  }

  .filed-color2 {
    color: #3a74f2;
    background: rgba(58, 116, 242, 0.1);
  }

  .d-normal-color {
    color: #606266;
  }

  .d-bold-color {
    color: #303133;
    font-weight: bold;
  }

  .d-normal-font {
    font-size: 12px;
  }

  .d-big-font {
    font-size: 14px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #f6f6f6;
    box-shadow: 0px 0px 0px 0px rgba(235, 238, 245, 1);
  }
}
</style>
<style lang="less">
.filedManage {
  .van-popover-zoom-enter-active {
    transform-origin: 0 0 0 !important;
  }

  .van-popover-zoom-leave-active {
    transform-origin: 0 0 0 !important;
  }
}
</style>
